.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes moveImage {
  0% {
    left: -100%; /* Starts from off-screen left */
  }
  100% {
    left: 100%; /* Ends off-screen right */
  }
}

.animated-image {
  position: absolute;
  top: 10%; /* Center vertically */
  left: -100%; /* Start the image off-screen to the left */
  transform: translateY(-50%); /* Center vertically */
  animation: moveImage 10s linear infinite; /* Adjust the duration (10s) as desired */
}

.App-header {
  background-image:url(./images/merchant.jpg);
  min-height: 100vh;
  background-size: cover;}

.App-header {
  background-image:url(./images/merchant.jpg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
}

.countdown-title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 55px;
  color: #e0e0e0;
}

.countdown-display {
  font-size: 36px;
  color: #4CAF50;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
}


.logo-container {
  position: fixed; /* Keeps the logo in a fixed position */
  top: 0; /* Distance from the top */
  right: 0; /* Distance from the right */
  padding: 10px; /* Adds padding around the logo */
  z-index: 1000; /* Ensures the logo is above other content */
  animation: float 3s ease-in-out infinite; /* Applies the "float" animation */
}

.logo-container img {
  width: 100px; /* Adjust based on your logo's size */
  height: auto;
}

/* Define the "float" animation */
@keyframes float {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px); /* Adjust the value to control the float height */
  }
}


/* Default image size for desktop */
.img-cloud {
  width: 200px; /* Adjust image size for desktop */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .img-cloud {
    width: 150px; /* Adjust image size for mobile */
  }
}

